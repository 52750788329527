.thank-you-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  background-color: #f0f4f8;
}

.thank-you-page h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.thank-you-page p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.join-button {
  display: inline-block;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.join-button:hover {
  background-color: #0056b3;
}
