* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --accent-color: #ebf4ff;
  --lighter-accent-color: #f5f9ff;
  --primary-text-color: #2c5282;
  --secondary-text-color: #fff;
  --primary-color: #4a90e2;
  --overlay-color: rgba(44, 82, 130, 0.2);
  --white-color: #fff;
  --shadow-color: rgba(74, 144, 226, 0.1);

  --hero-img-gradients: linear-gradient(
    213.55deg,
    #63a4ff 11.56%,
    rgba(74, 144, 226, 0) 63.58%
  );
  --background-diagonal-gradients: linear-gradient(
    238.86deg,
    #4a90e2 14.38%,
    rgba(74, 144, 226, 0) 75.41%
  );
  --background-gradients: linear-gradient(
    180deg,
    #4a90e2 14.38%,
    rgba(74, 144, 226, 0) 75.41%
  );
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  color: var(--primary-text-color);
  font-family: "Lexend", sans-serif;
  font-weight: 300;
  overflow-x: hidden;
}

/* navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 5rem;
  padding: 0 10vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--accent-color);
}

.nav-toggler {
  display: none;
}

.navbar .logo {
  margin-top: 30px;
  width: 250px; /* Adjust this value as needed */
  height: auto; /* This maintains the aspect ratio */
  max-width: 100%; /* Ensures it doesn't overflow on smaller screens */
  transition: all 0.3s ease; /* Smooth transition for any size changes */
}

@media (max-width: 768px) {
  .navbar .logo {
    width: 200px; /* Smaller size for mobile devices */
  }
}

.links-container {
  display: flex;
  gap: 1rem;
  list-style: none;
}

.links {
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  text-transform: capitalize;
  color: var(--primary-text-color);
}

/* hero section */
#hero-section {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: var(--lighter-accent-color);
  padding: 7rem 10vw 4rem;
  overflow: hidden;
}

.hero-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  height: 100%;
}

.hero-info {
  position: relative;
  width: 50%;
  min-width: 350px;
  z-index: 2;
}

.hero-heading {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
}

.hero-heading .title-wrapper {
  display: flex;
  align-items: baseline;
  gap: 0.8rem;
  margin-bottom: 0.4rem;
}

.hero-heading .time {
  color: var(--primary-text-color);
  letter-spacing: 0.5px;
}

.hero-heading .is {
  color: var(--primary-color);
  font-style: italic;
  font-weight: 500;
  font-size: 0.9em;
  position: relative;
  top: -2px;
}

.hero-heading .everything {
  display: block;
  color: transparent;
  background: linear-gradient(
    135deg,
    var(--primary-text-color) 0%,
    var(--primary-color) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  letter-spacing: 1px;
  font-size: 1.1em;
  margin-top: 0.2rem;
}

.hero-paragraph {
  line-height: 1.5;
  margin: 1.5rem 0 3rem;
  font-size: 1rem;
  color: #2c5282;
  text-align: left;
  max-width: 100%;
  word-wrap: break-word;
}

.hero-strong-text {
  font-weight: 500;
  color: #2c5282;
  display: block;
  margin-bottom: 1rem;
  letter-spacing: 0.3px;
  line-height: 1.6;
}

.hero-paragraph .fa-check-circle {
  margin-right: 0.4rem;
}

.highlight {
  color: #4a90e2;
  font-weight: 600;
  display: inline;
  white-space: normal;
  word-wrap: break-word;
  word-break: normal;
}

.hero-bullet-points {
  margin-top: 1.2rem;
  padding-left: 1.2rem;
}

.hero-bullet-points li {
  margin-bottom: 0.7rem;
  position: relative;
  padding-left: 1.2rem;
  list-style: none;
  font-size: 0.95rem;
  line-height: 1.4;
}

.hero-bullet-points li:before {
  content: "•";
  color: #4a90e2;
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  top: -0.2rem;
}

.btn {
  text-decoration: none;
  padding: 1rem 2rem;
  margin-right: 1rem;
  border-radius: 2rem;
  text-transform: capitalize;
  border: none;
  outline: none;
  cursor: pointer;
}

.primary-btn {
  background: #4a90e2;
  color: var(--secondary-text-color);
  transition: background-color 0.3s ease;
}

.primary-btn:hover {
  background-color: #63a4ff;
}

.secondary-btn {
  background: transparent;
  color: var(--primary-color);
  border: 0.1rem solid var(--primary-color);
}

/* hero section image container */
.hero-section-img-container {
  width: 30rem;
  height: 90%;
  position: absolute;
  right: 10vw;
  bottom: 0;
  display: flex;
  justify-content: center;
}

.hero-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 2;
}

.hero-section-img-container::before,
.hero-section-img-container::after,
.background-ele {
  content: "";
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25.5rem;
  height: 25.5rem;
  border-radius: 100%;
  background: var(--background-diagonal-gradients);
  z-index: 1;
}

.hero-section-img-container::before {
  width: 25rem;
  height: 25rem;
  background: var(--lighter-accent-color);
  z-index: 2;
}

.background-ele {
  top: 37%;
  left: 52%;
  width: 20rem;
  height: 20rem;
  z-index: 2;
  background: var(--background-diagonal-gradients);
}

.hero-img-social-link,
.about-img-social-link,
.contact-img-social-link {
  position: absolute;
  padding: 1rem;
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  background: #ebf4ff;
  box-shadow: 0 2rem 2rem rgba(74, 144, 226, 0.1);
  z-index: 3;
}

.hero-img-social-link img,
.about-img-social-link img,
.contact-img-social-link img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-img-social-link {
  top: 17%;
  right: 15%;
  transform: scale(0.75);
}

.subscribers {
  position: absolute;
  top: 20%;
  left: -5%;
  padding: 1rem 1.5rem;
  background: var(--white-color);
  box-shadow: 0 0.5rem 4rem var(--shadow-color);
  z-index: 3;
  border-radius: 0.5rem;
}

.subscribers h1 {
  font-weight: 400;
}

.subscribers p {
  font-size: 0.7rem;
  margin-top: 0.25rem;
}

.hero-review {
  padding: 1.5rem;
  border-radius: 0.5rem;
  position: absolute;
  bottom: 10%;
  right: -5%;
  background-color: white;
  border: 1px solid #ebf4ff;
  box-shadow: 0 4px 6px rgba(74, 144, 226, 0.1);
  width: 80%;
  z-index: 3;
  display: flex;
  gap: 1.5rem;
}

.hero-review i {
  color: #4a90e2;
  font-size: 2rem;
}

.hero-review p {
  font-family: "Lato", sans-serif;
  line-height: 1.75rem;
}

/* section */
section {
  padding: 6rem 10vw;
  position: relative;
}

.section-title {
  margin: 0.5rem 0;
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  line-height: 3.25rem;
}

.section-secondary-line {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 5rem;
}

/* brand section */
.brands-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.brand {
  background: var(--lighter-accent-color);
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  padding: 1rem;
}

.brand-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* about section */
#about-section {
  display: flex;
  align-items: center;
  gap: 8rem;
}

.about-us-img-container {
  position: relative;
  display: flex;
  width: 20rem;
  height: 35rem;
  flex: 0 0 auto;
  justify-content: center;
}

.about-us-img {
  width: 100%;
  max-height: 100%;
  background: var(--accent-color);
  overflow: hidden;
  border-radius: 10rem;
}

.about-us-img::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scaleY(0);
  width: 20.5rem;
  height: 35.5rem;
  background: var(--background-gradients);
  border-radius: 10rem;
  z-index: -1;
  transition: 0.25s;
}

.about-us-img.aos-animate::before {
  transform: translate(-50%, -50%) scaleY(1);
}

.about-us-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
}

.about-img-social-link:nth-child(2) {
  right: 30%;
  top: -5%;
}

.about-img-social-link:nth-child(3) {
  left: -10%;
  top: 20%;
}

.about-img-social-link:nth-child(4) {
  left: 15%;
  top: 30%;
}

.about-img-social-link:nth-child(5) {
  right: 10%;
  top: 20%;
}

/* about us */
.about-us {
  width: 100%;
  min-width: 350px;
}

.about-us .section-secondary-line {
  text-align: left;
  margin-bottom: 0;
}

.about-us .section-title {
  margin-bottom: 4rem;
  text-align: left;
}

.benefit-card {
  width: 100%;
  height: 5rem;
  margin-bottom: 2rem;
  border: 0.1rem solid #ebf4ff;
  border-radius: 0.5rem;
  display: flex;
  gap: 1.5rem;
  padding: 1rem;
  align-items: center;
  transition: 0.25s ease;
}

.card-img {
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  background: var(--accent-color);
  border-radius: 0.4rem;
  padding: 0.5rem;
}

.card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-name {
  font-size: 1.1rem;
}

.benefit-card:hover,
.service-card:hover {
  background: #ebf4ff;
  transform: scale(0.98);
}

/* services section */
.services-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.service-card {
  border: 0.1rem solid #ebf4ff;
  border-radius: 0.25rem;
  padding: 1.5rem;
  transition: 0.25s ease;
}

.service {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.service-img {
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  background-color: var(--accent-color);
  border-radius: 0.5rem;
}

.service-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.service-name {
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: capitalize;
}

.service-info {
  font-size: 0.9rem;
  line-height: 1.5rem;
}

.circle {
  position: absolute;
  pointer-events: none;
  z-index: -1;
  width: 10rem;
  height: 10rem;
  border-radius: 100%;
  background-color: var(--accent-color);
  opacity: 0.75;
}

.circle.right {
  top: -3rem;
  right: -3rem;
}

.circle.left {
  bottom: -10rem;
  left: -2rem;
}

/* faqs */
#faq-section .section-secondary-line {
  margin-bottom: 1rem;
}

#faq-section .section-title {
  margin-bottom: 5rem;
}

.faq {
  width: 100%;
  border-radius: 0.25rem;
  border: 0.1rem solid var(--accent-color);
  position: relative;
  margin-bottom: 2rem;
  transition: 0.25s ease;
}

.question-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
}

.question {
  font-weight: 400;
}

.question-box i {
  width: 2rem;
  flex: 0 0 auto;
  text-align: center;
  transform: rotate(180deg);
  transition: 0.5s;
  color: var(--primary-color);
}

.answer-box {
  display: grid;
  grid-template-rows: 0fr;
  transition: 0.5s;
}

.answer {
  overflow: hidden;
  line-height: 2rem;
  padding: 0 2rem;
}

.faq.active .question-box {
  background: var(--lighter-accent-color);
}

.faq.active .question-box i {
  transform: rotate(0deg);
}

.faq.active .answer-box {
  padding: 2rem 0rem;
  grid-template-rows: 1fr;
}

.faq:hover:not(.active) {
  transform: scale(0.99);
  background: var(--lighter-accent-color);
}

/* contact section */
#contact-section {
  background: #ebf4ff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}

.contact-us-img-container {
  position: relative;
  display: flex;
  width: 25rem;
  height: 40rem;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
}

.contact-us-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
}

.contact-us-img-container::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25rem;
  height: 25rem;
  border-radius: 100%;
  background: var(--background-diagonal-gradients);
  z-index: 1;
}

.contact-us-img-container::after {
  content: "Contact Us. We can't wait to work with you and grow your business.";
  position: absolute;
  bottom: -2rem;
  padding: 2rem;
  line-height: 2rem;
  border-radius: 0.5rem;
  background: var(--white-color);
  z-index: 2;
  text-align: center;
}

.contact-img-social-link:nth-child(2) {
  left: -5%;
  top: 50%;
}

.contact-img-social-link:nth-child(3) {
  right: 10%;
  top: 20%;
}

.contact-img-social-link:nth-child(4) {
  left: 10%;
  top: 20%;
}

.contact-img-social-link:nth-child(5) {
  right: 0%;
  bottom: 30%;
}

.contact-us {
  width: 100%;
  min-width: 350px;
}

.contact-us .section-title {
  text-align: left;
}

.contact-form {
  margin-top: 3rem;
}

input,
textarea {
  display: block;
  width: 100%;
  height: 3.5rem;
  border: none;
  padding: 1rem 1.5rem;
  outline: none;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  font-size: 1rem;
}

textarea {
  font-family: "Lexend", sans-serif;
  resize: none;
  height: 20rem;
  font-weight: 300;
}

::placeholder {
  color: var(--primary-text-color);
  opacity: 0.5;
}

/* footer */
#footer {
  position: relative;
  padding: 4rem 5vw;
  display: flex;
  justify-content: space-between;
  gap: 4rem;
}

.company-info .logo {
  height: 3rem;
  margin-bottom: 2rem;
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-links a {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  border-radius: 100%;
  background: var(--lighter-accent-color);
}

.social-links a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* footer */
.footer-links-container {
  justify-self: end;
}

.footer-links-title {
  text-transform: none;
  margin-bottom: 1rem;
  font-weight: 500;
}

.footer-links,
.footer-text {
  color: var(--primary-text-color);
  text-decoration: none;
  margin-bottom: 0.5rem;
  display: block;
  text-transform: none;
}

.footer-text {
  margin-bottom: 1rem;
}

.footer-links:hover {
  color: var(--primary-color);
}

.copyright {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  text-align: center;
  padding: 0.5rem;
  color: var(--secondary-text-color);
  background: var(--primary-color);
  text-transform: none;
}

/* media query */
@media (max-width: 1440px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 1200px) {
  html {
    font-size: 14.5px;
  }
}

@media (max-width: 998px) {
  html {
    font-size: 13px;
  }

  /* hero section */
  #hero-section {
    min-height: auto;
    padding: 10rem 10vw;
  }

  .hero-heading {
    font-size: 3rem;
    line-height: 4rem;
  }

  .hero-section-img-container {
    transform-origin: bottom;
    transform: scale(0.9) translateX(5rem);
  }

  /* section */
  section {
    padding: 3.5rem 10vw;
  }

  /* contact section */
  #contact-section {
    gap: 2rem;
  }

  .contact-us-image-container {
    transform: scale(0.9);
  }
}

@media (max-width: 768px) {
  /* navbar  */
  .nav-toggler {
    display: block;
    width: 3rem;
    height: 3rem;
    border: 0.1rem solid var(--primary-color);
    border-radius: 0.2rem;
    position: relative;
    cursor: pointer;
    transition: 0.5s;
    margin-left: 2rem;
  }

  .nav-toggler span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 0.1rem;
    border-radius: 1rem;
    background: var(--primary-color);
    transition: 1s cubic-bezier(0.77, -0.29, 0.5, 1.66);
  }

  .nav-toggler span:nth-child(1) {
    top: 30%;
  }

  .nav-toggler span:nth-child(3) {
    top: 70%;
  }

  .nav-toggler.active {
    border-color: transparent;
  }

  .nav-toggler.active span:nth-child(1) {
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .nav-toggler.active span:nth-child(2) {
    width: 0;
  }

  .nav-toggler.active span:nth-child(3) {
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .links-container {
    display: block;
    width: 100%;
    left: 0;
    top: 100%;
    position: absolute;
    background: var(--accent-color);
    transition: 0.5s;
    height: 0;
    overflow: hidden;
    z-index: -1;
  }

  .links-container.active {
    height: 16rem;
  }

  .links-container .link-items {
    text-align: left;
    height: 4rem;
  }

  .links-container .links {
    display: block;
    width: 100%;
    margin: auto;
    transition: 0.25s;
    padding: 1.2rem 10vw;
  }

  .links-container .links:hover {
    background-color: var(--primary-color);
    color: var(--secondary-text-color);
  }

  /* hero section */
  #hero-section {
    flex-direction: column-reverse;
    padding: 5rem 10vw;
    gap: 5rem;
  }

  .hero-info {
    width: 80%;
    text-align: center;
  }

  .hero-section-img-container {
    position: relative;
    width: 350px;
    height: 500px;
  }

  .hero-img {
    height: 100%;
  }

  .hero-review {
    bottom: 0;
    right: 10%;
  }

  .subscribers {
    top: 10%;
    left: 0%;
    transform: scale(1);
  }

  /* brands */
  .brands-container {
    justify-content: center;
    gap: 2rem;
  }

  /* about section */
  #about-section,
  #contact-section {
    padding: 2rem 10vw;
    flex-direction: column;
    gap: 5rem;
  }

  /* services */
  .circle.right {
    display: none;
  }

  /* footer */
  #footer {
    flex-direction: column;
    padding: 4rem 10vw;
    gap: 2rem;
  }

  .footer-links-container:not(.contact) {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .footer-links-title:not(.footer-links-container.contact .footer-links-title) {
    min-width: 100%;
    margin-bottom: 0;
  }

  .hide-on-mobile {
    display: none !important;
  }

  /* Update hero review styles for better mobile layout when visible */
  .hero-review {
    width: 90%;
    padding: 1rem;
    font-size: 0.9rem;
    right: 5%;
    bottom: 5%;
  }

  .hero-review i {
    font-size: 1.5rem;
  }

  .hero-review p {
    line-height: 1.5;
    font-size: 0.9rem;
  }
}

@media (max-width: 560px) {
  html {
    overflow-x: hidden;
  }

  /* hero-section */
  .hero-info {
    width: 100%;
  }

  .hero-heading {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }

  /* services */
  .services-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .hero-paragraph {
    padding: 0;
    margin: 1.2rem 0 2rem;
  }

  .hero-strong-text {
    display: block;
    text-align: left;
    padding: 0;
    margin-bottom: 1rem;
    font-size: 0.95rem;
    line-height: 1.6;
  }

  .highlight {
    display: inline;
    padding: 0;
  }
}

/* Add styles for the email link */
.footer-email {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-email:hover {
  color: var(--primary-text-color);
  text-decoration: underline;
}

/* Update existing footer text styles */
.footer-text {
  color: var(--primary-text-color);
  margin-bottom: 1rem;
  display: block;
  text-transform: none;
}

/* Fix text wrapping for highlighted spans */
.highlight {
  display: inline;
  white-space: normal;
  word-break: break-word;
}

/* Ensure proper spacing around bullet points */
.hero-bullet-points li:before {
  top: 0;
}
